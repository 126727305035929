<template>
    <section>
        <navbar-admin>
            <navbar-config-redirect-element 
            tooltip="Ir a talleres"
            route="talleres.main"
            icon="icon-hammer-screwdriver" 
            />
        </navbar-admin>
        <tabs :tabs="tabsTalleres" />
        <router-view />
    </section>
</template>
<script>
export default {
    data(){
        return{
            tabsTalleres: [
                {
                    titulo:'Tipos de servicios',
                    ruta:"configurar.talleres.tipos.servicios",
                },
            ],
        }
    },
    created(){
        this.$try(() => {
            this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
                'configurar.talleres', 
                'configurar.talleres.tipos.servicios'
            ]);
        })
    }
}
</script>